import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash } from '@fortawesome/free-solid-svg-icons';

class TBody extends Component {

  renderEntryCells = (entry) => {
    let entryCells = [];
    let id = entry.id;

    for(let i = 0; i < this.props.properties.length; i++) {
      entryCells.push(
        <td key={this.props.properties[i].name+'_'+id}>{entry[this.props.properties[i].name]}</td>
      );
    }

    entryCells.push(
      <td key={'edit_'+id} className="entryEdit">
        <button className="button" onClick={() => this.props.requestModal(+id, 'edit')}>
          <span className="icon is-small">
            <FontAwesomeIcon icon={faEdit} />
          </span>
        </button>
      </td>
    );
    entryCells.push(
      <td key={'delete_'+id} className="entryDelete">
        <button className="button" onClick={() => this.props.requestModal(+id, 'delete')}>
          <span className="icon is-small">
            <FontAwesomeIcon icon={faTrash} />
          </span>
        </button>
      </td>
    );

    return entryCells;
  }

  renderEntries = () => {
    let entries = [];
    for(let entry of this.props.entries) {
      entries.push(
        <tr key={'entry_'+entry.id}>
          {this.renderEntryCells(entry)}
        </tr>
      );
    }
    return entries;
  }


  render() {
    return (
      <tbody>
        {this.renderEntries()}
      </tbody>
    );
  }
}

export default TBody;
