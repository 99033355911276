import React, { Component } from 'react';
import Paginator from './Paginator';
import THead from './THead';
import TBody from './TBody';
import Modal from './Modal';
import AddEntryModal from './modalContents/AddEntryModal';
import EditEntryModal from './modalContents/EditEntryModal';
import DeleteEntryModal from './modalContents/DeleteEntryModal';

class Table extends Component {
  constructor(props) {
    super(props)
    this.state = {
      lastSort: {
        key: 'None',
        direction: 'asc'
      },

      pagination: {
        pages: 0,
        currentPage: 1,
        entriesPerPage: 10
      },

      modal: {
        isVisible: false,
        mode: 'None',
        data: []
      },

      entries: this.props.entries,
      entryProperties: this.props.properties
    }
  }

  closeModal = () => {
    this.setState(() => ({
      modal: {
        isVisible: false,
        mode: 'None',
        data: []
      }
    }));
  }

  getIndexByID = (entryID) => {
    return this.state.entries.indexOf(this.state.entries.filter(entry => {return entry.id === entryID})[0]);
  }

  getNewId = () => {
    let entriesCopy = [...this.state.entries];
    let highestID = 0;
    for(let entry of entriesCopy) {
      if(entry.id >= highestID)
        highestID = entry.id+1;
    }
    return highestID;
  }

  addEntry = (newEntry) => {
    let entriesCopy = [...this.state.entries];

    newEntry.id = 0;
    for(let entry of entriesCopy) {
      if(entry.id >= newEntry.id)
        newEntry.id = entry.id+1;
    }

    entriesCopy.push(newEntry);

    this.setState((prevState) => ({
      entries: entriesCopy,
      pagination: {
        ...prevState.pagination,
        pages: Math.ceil(entriesCopy.length/this.state.pagination.entriesPerPage)
      }
    }));
    /*
      Insert API edit request magic in here..
    */

    this.closeModal();
    return true; //succesfully added through backend
  }

  editEntry = (editedEntry) => {
    let index = this.getIndexByID(editedEntry.id);
    let editedEntries = [...this.state.entries];
    editedEntries[index] = editedEntry;
    this.setState(() => ({
      entries: editedEntries
    }));
    /*
      Insert API edit request magic in here..
    */
    this.closeModal();
    return true; //succesfully updated through backend
  }

  deleteEntry = (entryID) => {
    let index = this.getIndexByID(entryID);
    let entriesCopy = [...this.state.entries];
    entriesCopy.splice(index, 1);
    this.setState((prevState) => ({
      entries: entriesCopy,
      pagination: {
        ...prevState.pagination,
        pages: Math.ceil(entriesCopy.length/this.state.pagination.entriesPerPage)
      }
    }));
    /*
      Insert API delete request magic in here..
    */
   this.closeModal(); //succesfully deleted through backend
   return true;
  }

  showModal = () => {
    let modal = this.state.modal;
    let data = {}

    if(modal.isVisible === true) {
      let modalTitle = '';
      let modalBody = '';
      switch(modal.mode) {
        case 'add':
          modalTitle = 'Add Entry';
          data.addEntry = this.addEntry;
          data.entryProperties = this.state.entryProperties;
          modalBody = <AddEntryModal data={data} />
          break;
        case 'edit':
          modalTitle = 'Edit Entry';
          data.entry = this.state.entries[this.getIndexByID(modal.data)];
          data.editEntry = this.editEntry;
          data.entryProperties = this.state.entryProperties;
          modalBody = <EditEntryModal data={data} />;
          break;
        case 'delete':
          modalTitle = 'Delete Entry';
          data.entry = this.state.entries[this.getIndexByID(modal.data)];
          data.closeModal = this.closeModal;
          data.deleteEntry = this.deleteEntry;
          modalBody = <DeleteEntryModal data={data} />;
          break;
        default:
          modalTitle = 'Error occured!'
      }

      return <Modal
              modalTitle={modalTitle}
              modalBody={modalBody}
              closeModal={this.closeModal}/>
    }
  }

  requestModal = (id, mode) => {
    this.setState(() => ({
      modal: {
        isVisible: true,
        mode: mode,
        data: id
      }
    }));
  }

  componentDidMount = () => {
    this.setState(prevState => ({
      pagination: {
        ...prevState.pagination,
        pages: Math.ceil(this.state.entries.length/this.state.pagination.entriesPerPage),
        currentPage: 1
      }
    }));
  }

  compareBy = (key, direction) => {
    if(direction === 'asc') {
      return function (a, b) {
        if (a[key] < b[key]) return -1;
        if (a[key] > b[key]) return 1;
        return 0;
      }
    }
    else {
      return function (a, b) {
        if (b[key] < a[key]) return -1;
        if (b[key] > a[key]) return 1;
        return 0;
      }
    }
  }

  sortBy = (key) => {
    let direction = 'asc';
    if(key === this.state.lastSort.key) {
      direction = ((this.state.lastSort.direction === 'asc')? 'desc' : 'asc');
    }

    let entriesCopy = [...this.state.entries];
    entriesCopy.sort(this.compareBy(key, direction));

    this.setState(() => ({
      entries: entriesCopy,
      lastSort: {
        key: key,
        direction: direction
      }
    }));
  }

  getEntries = () => {
    let bound = {
      lower: (this.state.pagination.currentPage-1)*this.state.pagination.entriesPerPage,
      upper: this.state.pagination.currentPage*this.state.pagination.entriesPerPage
    }

    let currentPageEntries = [];
    let length = this.state.entries.length;
    for(let i = bound.lower; i < ((bound.upper > length)? length : bound.upper); i++)
      currentPageEntries.push(this.state.entries[i]);
    return currentPageEntries;
  }

  render() {
    return (
      <div>
        <div>
          <button className="button" onClick={() => this.requestModal('None', 'add')}>
            Add Entry
          </button>
        </div>
        <div className="box" id="tableWrapper">
          {
            (this.state.entries.length > 0)?
            <div>
              <div className="is-scrollable">
                <table className="table is-hoverable is-fullwidth is-scrollable">
                  <THead properties={this.state.entryProperties} sortBy={this.sortBy}/>
                  <TBody entries={this.getEntries()} properties={this.state.entryProperties} lastSort={this.state.lastSort} requestModal={this.requestModal}/>
                </table>
              </div>
              <hr />
              <div className="center-all">
                <Paginator pagination={this.state.pagination} />
              </div>
            </div>
            :
            <div className="center-all is-italic">Sadly, no entries found... Please add some by clicking on the 'Add Entry' button!</div>
          }


        </div>
        <div>
          {this.showModal()}
        </div>
      </div>
    )
  }
}

export default Table;
