import React, { Component } from 'react';
import Table from './components/Table';
import jquery from 'jquery-ajax';

let server = 'https://aquamat.tech/php_api/index.php';

class App extends Component {
  constructor(props) {
    super(props)
    this.state = {
      entries: [],
      properties: [
        {
          title: '#',
          name: 'id',
          type: 'number'
        }
      ]
    }
    this.fetchData = this.fetchData.bind(this);
  }

  fetchData = async () => {
    let data = await fetch(server+'?a=get_all', {
      mode: 'cors',
      cache: 'no-cache'
    }).then((response) => {
      if(response.ok)
        return response.json();
      else
        console.error(response);
    }).then((json) => {
      return json;
    });
    return data;
  }

  updateData = (json) => {
    this.setState({
      entries: json.entries,
      properties: json.entryProperties
    });
    console.log(this.state);
  }

  componentDidMount = () => {
    let thisObj = this;
    jquery.ajax({
      url: server+'?a=get_all',
      method: 'get',
      async: false,
      success: function (res) {
        res = JSON.parse(res);
        thisObj.setState(() => ({
          entries: res.entries,
          properties: res.entryProperties
        }));
      }
    });

  }

  render() {

    return (
      <div className="App">

          <Table entries={this.state.entries} properties={this.state.properties} />

      </div>
    );
  }
}

export default App;
