import React, { Component } from 'react';

class Paginator extends Component {

  /**
   * Generate a button DOM Element with the page number
   * and the class namesignalizing current page.
   * @param  {Integer} buttonNum Number of the page to which the button is reffering.
   * @return {Element}           Generated button DOM Element.
   */
  getButton = (buttonNum) => {
    return (
      <li key={'pageLink_'+buttonNum}>
        <button className={'pagination-link button'+((this.props.pagination.currentPage === buttonNum)? ' is-current' : '')} onClick={() => this.props.pagination.renderPage(+buttonNum)}>
          {buttonNum}
        </button>
      </li>
    );
  }

  /**
   * Generate a hellip (...) DOM Element with variable key suffix.
   * @param  {Integer} keySuffix Order of the hellip element in the pagination (1 or 2), by default empty string.
   * @return {Element}           Generated hellip DOM element.
   */
  getHellip = (keySuffix = '') => {
    return (
      <li key={'hellip'+keySuffix}>
        <span className="pagination-ellipsis">
          &hellip;
        </span>
      </li>
    );
  }

  /**
   * Generate an array of pagination controls (buttons) and hellips elements if needed.
   * @return {Array} Array of buttons and hellips.
   */
  renderButtons = () => {
    let count = this.props.pagination.pages;
    let currentPage = this.props.pagination.currentPage;
    let maxButtons = 5;
    let buttons = [];

    if(count <= maxButtons+2) {
      for(let i = 1; i <= count; i++) {
        buttons.push(
          this.getButton(i)
        );
      }
      return buttons;
    }
    else {
      if(currentPage < maxButtons) {
        //Too close to the beggining, only ending hellip needed
          for(let i = 1; i <= maxButtons; i++) {
            buttons.push(
              this.getButton(i)
            );
          }
          buttons.push(this.getHellip());
          buttons.push(this.getButton(count))
          return buttons;
      }
      else if(currentPage > count-maxButtons+1) {
        //Too close to the end, only starting hellip needed
        buttons.push(this.getButton(1));
        buttons.push(this.getHellip());
        for(let i = count-maxButtons+1; i <= count; i++) {
          buttons.push(
            this.getButton(i)
          );
        }
        return buttons;
      }
      else {
        //Both hellips needed, far away enough to the point where array of buttons would be too long
        //Hellips are assigned unique key suffix, just so we prevent any React warnings
        buttons.push(this.getButton(1));
        buttons.push(this.getHellip('_1'));
        for(let i = currentPage-Math.floor((maxButtons-3)/2); i <= currentPage+Math.floor((maxButtons-3)/2); i++) {
          buttons.push(
            this.getButton(i)
          );
        }
        buttons.push(this.getHellip('_2'));
        buttons.push(this.getButton(count));
        return buttons;
      }
    }
  }

  render() {
    return (
      <nav className="pagination">
        <ul className="pagination-list">
          {this.renderButtons()}
        </ul>
      </nav>
    )
  }
}

export default Paginator;
