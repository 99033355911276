import React, { Component } from 'react';
//Import FA Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
//Import validation function
import validate from '../functions/inputValidator.js';

class InputField extends Component {

  constructor(props) {
    super(props)
    this.state = {
      inpVal: JSON.parse(JSON.stringify(this.props.value)),
      errors: []
    }
  }

  /**
   * Handling onChange event of the data input,
   * passing information about data validity to the parent component
   * and updating state of this component (value of input, errors as a reference to validity).
   * @param  {Event} e The onChange event of the input.
   */
  handleChange = (e) => {
    let value = e.target.value;
    let errors = validate(value, this.props.property);

    this.props.setValidity(this.props.property.name, (errors.length === 0), value);

    this.setState(() => ({
      inpVal: value,
      errors: errors
    }));
  }

  /**
   * Get a icon which is used to inform user
   * about validity of the data he entered.
   * @return {Component} Icon corresponding to the data validity state.
   */
  getIcon = () => {
    if(this.state.errors.length === 0) {
      return <FontAwesomeIcon icon={faCheck} />
    }
    else {
      return <FontAwesomeIcon icon={faExclamationTriangle} />
    }
  }

  /**
   * Get an array of errors returned
   * from validation process and render them (if there are any).
   * @return {Array} Array of errors
   */
  getErrors = () => {
    let errors = [];
    for(let error of this.state.errors) {
      errors.push(
        <p key={'err_'+this.state.errors.indexOf(error)}>{error}</p>
      );
    }
    return errors;
  }

  /**
   * This function performs initial data validation,
   * just to check data integrity and most importantly
   * to pass validity state to the parent component
   * (needed for allowing interaction with the submit buton).
   */
  componentDidMount = () => {
    let errors = validate(this.state.inpVal, this.props.property);

    this.props.setValidity(this.props.property.name, (errors.length === 0), this.state.inpVal);
    this.setState(() => ({
      errors: errors
    }));
  }

  render() {
    return (
      <div className="field">
        <p className="control has-icons-right">
          <input
            value={this.state.inpVal}
            type={this.props.property.type}
            placeholder={this.props.property.title}
            onChange={(e) => this.handleChange(e)}
            className={'input'+((this.state.errors.length > 0)? ' is-danger' : ' is-success')}
          />
          <span className="icon is-small is-right">
            {this.getIcon()}
          </span>
        </p>
        <div>
          {this.getErrors()}
        </div>
      </div>
    )
  }
}

export default InputField;
