import React, { Component } from 'react';
//Import FA Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSort } from '@fortawesome/free-solid-svg-icons';

class THead extends Component {

  renderCells = () => {
    let cells = [];

    for(let i = 0; i < this.props.properties.length; i++) {
      cells.push(
        <th key={this.props.properties[i].name}>
          <button onClick={() => this.props.sortBy(this.props.properties[i].name)}>
            {this.props.properties[i].title}
            &nbsp;
            <span className="icon is-small">
              <FontAwesomeIcon icon={faSort} />
            </span>
          </button>
        </th>
      )
    }

    return cells;
  }

  render() {
    return (
      <thead>
        <tr>
          {this.renderCells()}
          <th key="edit"></th>
          <th key="delete"></th>
        </tr>
      </thead>
    );
  }
}

export default THead;
