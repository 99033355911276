import React, { Component } from 'react';
//Import FA Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';

class Modal extends Component {

  render() {
    return (
      <div className="modal is-active">
        <div className="modal-background"></div>
        <div className="modalContent block box">
          <div className="modalHeader">
            <h3 className="title is-3 center-all">{this.props.modalTitle}</h3>
          </div>
          <hr/>
          <div className="modalBody">
            {this.props.modalBody}
          </div>
          <hr/>
          <div className="modalFooter center-all">
            <button className="button" onClick={() => this.props.closeModal()}>
              <FontAwesomeIcon icon={faTimesCircle} />
              &nbsp;
              Close Me
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Modal;
