import React, { Component } from 'react';

class DeleteEntryModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      isLoading: false
    }
  }

  /**
   * Basic onClick event callback,
   * un/sets the loading state just so the button
   * has a loading animation in the meantime
   * while the data is being processed.
   */
  handleClick = () => {
    this.setState(() => ({
      isLoading: true
    }));

    if(this.props.data.deleteEntry(this.props.data.entry.id)) {
      this.setState(() => ({
        isLoading: false
      }));
    }
  }

  render() {
    return (
      <div className="center-all flex-column">
        Do you really want to delete this entry?
        <div className="user-options center-all">
          <button
            className="button is-danger"
            onClick={() => this.props.data.closeModal()}>
            No
          </button>
          &nbsp;
          <button
            className={'button is-success'+((this.state.isLoading)? ' is-loading' : '')}
            onClick={() => {this.handleClick();}}>
            Yes
          </button>
        </div>
      </div>
    )
  }
}

export default DeleteEntryModal;
