import React, { Component } from 'react';
import InputField from '../InputField';

class EditEntryModal extends Component {

  constructor(props) {
    super(props)
    this.state = {
      entryCopy: JSON.parse(JSON.stringify(this.props.data.entry)),
      isLoading: false,
      validity: {}
    }
  }

  /**
   * Create an array of input fields (input, icon) from props,
   * should only be called from render(), just so it can be rendered "directly".
   * @return {Array} Array of InputField Components
   */
  renderInputs = () => {
    let properties = this.props.data.entryProperties;
    let entry = this.state.entryCopy;
    let fields = [];

    for(let i = 1; i < properties.length; i++) {
      fields.push(
        <InputField
          key={'field_'+properties[i].name+'_'+entry.id}
          property={properties[i]}
          value={entry[properties[i].name]}
          setValidity={this.setValidity}
        />
      );
    }

    return fields;
  }

  /**
   * Used to store validated entryCopy and it's validity state after it's edit .
   * @param {Object}  propertyName Name of a property of the entry which was edited and validated.
   * @param {Boolean} isValid      Response from validation process, such as validate() function.
   * @param value                  New value of the edited property.
   */
  setValidity = (propertyName, isValid, value) => {
    let validityCopy = this.state.validity;
    let entryCopy = this.state.entryCopy;

    validityCopy[propertyName] = isValid;
    entryCopy[propertyName] = value;

    this.setState((prevState) => ({
      validity: validityCopy,
      entryCopy: entryCopy
    }));
  }

  /**
   * Check whether the form could be submitted or not,
   * result is based on the validity state of entry properties.
   * @return {Boolean} If form is submitable.
   */
  isSubmitable = () => {
    for(let i = 1; i < this.props.data.entryProperties.length; i++) {
      if(this.state.validity[this.props.data.entryProperties[i].name] === false) {
        return false;
      }
    }
    return true;
  }

  /**
   * Handling onSumbit event of the form,
   * passing data to be stored at backend,
   * basic error detection and button's content
   * change is performed in this function.
   * @param  {Event} e The onSubmit event of the form.
   */
  handleSubmit = (e) => {
    //Prevent reloading the page on the submit event
    e.preventDefault();

    if(this.isSubmitable()) {
      this.setState(() => ({
        isLoading: true
      }));
      if(this.props.data.editEntry(this.state.entryCopy)) {
        this.setState(() => ({
          isLoading: false
        }));
      }
      else {
        console.error('API Error occured. Please contact the administrator.');
      }
    }
  }

  render() {
    return (
      <div className="editEntry">
        <form onSubmit={(e) => this.handleSubmit(e)}>
          {this.renderInputs()}
          <div className="field">
            <div className="control user-options center-all">
              <button
                className={'button is-info'+((this.state.isLoading)? ' is-loading' : '')}
                disabled={
                //Disable button if form can not be submitted because of validation reasons.
                !this.isSubmitable()
              }>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    )
  }
}

export default EditEntryModal;
