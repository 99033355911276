/**
 * This function provides basic validation by rules specified in the 'property' attribute.
 * @param value     Value to be validated.
 * @param property  Property should be object with following keys: min, max, type, name.
 * @return          Returns errors for the value specified.
 */
function validate(value, property) {
  let errors = [];
  switch (property.type) {
    case 'string':
      if(typeof(value) === 'string') {
        if(!(property.min <= value.length))
          errors.push('The minimal length is '+property.min+' character(s).')
        if(!(value.length <= property.max))
          errors.push('The maximal length is '+property.max+' character(s).')
        if(!(/\S/.test(value)) && value.length > 0)
          errors.push('Please include non-white characters.')
      }
      else {
        errors.push('Invalid datatype!');
      }
      break;
    case 'number':
      if(!isNaN(value)) {
        if(!(property.min <= value))
          errors.push('The minimal value is '+property.min+'.');
        if(!(property.max >= value))
          errors.push('The maximal value is '+property.max+'.');
      }
      else {
        errors.push('Invalid datatype!');
      }
      break;
    case 'date':
      if(!isNaN(new Date(value))) {
        if(!(new Date(property.min) <= new Date(value)) || !(new Date(property.max) >= new Date(value)))
          errors.push('The date is not within the valid range.')
      }
      else {
        errors.push('Invalid data format!');
      }
      break;
    default:
      console.log('Input could not be validated since it\'s datatype does not match any validatable type.');
  }
  return errors;
}

export default validate;
